import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/cpu-bottleneck"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/cpubottleneck.png"}) { ...eyecatchImg },
    cpubottleneck: file(relativePath: { eq: "gpu/cpu-bottleneck.png"}) { ...normalImg },
    cpurole: file(relativePath: { eq: "gpu/cpurole.png"}) { ...normalImg },
    requirement_hq: file(relativePath: { eq: "gpu/requirement_hq.PNG"}) { ...normalImgWithHQ },
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SaleLoadable = makeShortcode("SaleLoadable");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`CPUのボトルネックを回避するGPUとの組み合わせは？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="CPUのボトルネックを回避する" mdxType="Image" />
    <p>{`パソコンの購入時にGPUをまず決定して、CPUを選びたい場合、どのレンジのCPUにしようかと迷うことがある。`}</p>
    <p>{`よくおすすめされる方法が、`}<strong parentName="p"><em parentName="strong">{`大体同レベルのものを抑えておけば間違えない`}</em></strong>{`という考えである。`}</p>
    <p>{`すなわち３～４万円程度のミドルレンジのGPUならばCPUはミドルレンジといわれるCore i5、５万円を超えるようなGPUを積むならばCore i7,9を搭載するといった具合である。`}</p>
    <p>{`この考え方は間違えではなく、私も人に聞かれるとそのように回答するが、`}<strong parentName="p">{`言うなれば大は小を兼ねるという考え方であり、`}<em parentName="strong">{`コストを考えると常に最適解とはならない`}</em></strong>{`。`}</p>
    <p>{`本稿では、なぜCPUのボトルネックが発生するのかを理解し、要件にあったCPUとGPUの組み合わせを自力で選べるようになることを目指す。`}</p>
    <p>{`考え方が分かればあとは`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`や`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`等を参考に最終決定していこう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "CPUがボトルネックとはどのような状態か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%8C%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF%E3%81%A8%E3%81%AF%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AA%E7%8A%B6%E6%85%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "CPUがボトルネックとはどのような状態か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUがボトルネックとはどのような状態か？`}</h2>
    <Image {...props} name="cpubottleneck" caption="CPUのボトルネック" mdxType="Image" />
    <p>{`ゲームの滑らかさを決めるフレームレートはGPUの能力によるところが大きく、より滑らかな映像を実現するためにはより高価なGPUと交換することが一般的である。`}</p>
    <p>{`ところがCPUの能力が低いと、`}<strong parentName="p">{`せっかく高価なGPUと交換したにも関わらず、FPSが全く伸びない`}</strong>{`という状態になり得る。この状態を`}<strong parentName="p"><em parentName="strong">{`CPUがボトルネック`}</em></strong>{`であるという。`}</p>
    <p>{`逆にGPUを交換することによりフレームレートが上がる場合は、`}<strong parentName="p">{`GPUがフレームレートのボトルネックになっている`}</strong>{`という事が言えるが、こちらの方が一般的なケースが多いため普通GPUのボトルネックに言及されることはない。`}</p>
    <p>{`次の節からはGPU、CPUのゲームにおける役割を抑え、何故ボトルネックが発生するのかを理解していこう。`}</p>
    <h2 {...{
      "id": "ゲームにおけるGPUの役割",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8BGPU%E3%81%AE%E5%BD%B9%E5%89%B2",
        "aria-label": "ゲームにおけるGPUの役割 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームにおけるGPUの役割`}</h2>
    <p>{`GPUのゲームにおける役割は描画を滑らかに行うことである。`}</p>
    <p>{`具体的には毎フレームごとに各ピクセルの色や透明度を決定し、それをフレームバッファーという描画のためのメモリ領域に書き込むという操作が必要となる。`}</p>
    <p>{`これを行うためには操作している人や背景、敵などの位置を計算する`}<em parentName="p">{`座標変換`}</em>{`や、光源と物体の位置関係や光の反射を計算して最終的なピクセルごとの色を決定する`}<em parentName="p">{`ライティング`}</em>{`(lighting)などのタスクが必要となる。`}</p>
    <p>{`GPUの一つ一つのコアの能力は1GHzそこそこで到底CPUには及ばないものの、コア数は数千にも及び膨大なピクセルに対する演算を並列処理で高速にこなす事ができる。`}</p>
    <p>{`例えば背景の視点が回転して変わった時に、各ピクセルがどこにマッピングされるかは、他のピクセルとの依存関係のない行列演算となるため、並列演算に適した処理となる。`}</p>
    <p>{`この`}<strong parentName="p"><em parentName="strong">{`超並列演算`}</em>{`により、60FPSならばわずか16.66msという短い時間内に描画に必要な計算処理を全て済ますことができる`}</strong>{`のである。`}</p>
    <p>{`一方でCPUではコア数が多くて数十個であるため、それぞれのコアの能力は高くとも、膨大な描画演算をこなす事はできない。`}</p>
    <p>{`CPUとGPUの違いを車で例えるならば、CPUはスーパーカーで、GPUはバスであり、移動距離x人数を考えるとGPUの方が効率が良い。同じ処理を複数の対象に適用する場合にGPUは力を発揮する。`}</p>
    <h2 {...{
      "id": "ゲームにおけるCPUの役割",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8BCPU%E3%81%AE%E5%BD%B9%E5%89%B2",
        "aria-label": "ゲームにおけるCPUの役割 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームにおけるCPUの役割`}</h2>
    <Image {...props} name="cpurole" alt="CPUが果たす役割" mdxType="Image" />
    <p>{`GPUがメインの描画を担う一方で、CPUはたいして働いていないと思う人は多いかもしれない。`}</p>
    <p>{`しかし実際には`}<strong parentName="p">{`CPUは物体同士が衝突して向きを変える物理演算、敵AIの制御、サウンドエフェクト、UI更新や操作ユーザの制御、オンラインゲームならばサーバとの同期処理など非常に多くのことを担う`}</strong>{`。`}</p>
    <p>{`60FPSと聞くと、１秒に60回描画することだと想像する人が多く、その通りなのだが、`}<strong parentName="p"><em parentName="strong">{`１フレームの16.66msの間に上記の演算も全て終えなければならない`}</em></strong>{`。`}</p>
    <p>{`すなわち一般的にはGPUがボトルネックとなるケースが多いものの、CPUの処理が多く要求されるゲームとなると、今度は逆にCPUがボトルネックとなることもあるのである。`}</p>
    <p>{`このため実際のゲーム開発の現場では、プロジェクトの初期段階からCPUのパフォーマンスをチェックし、AI制御や更新するUIの簡素化、複数コアを演算で使えるようにプログラミングをマルチスレッドなものに変更するなど日々チューニングに励んでいるのである。`}</p>
    <h2 {...{
      "id": "CPUのボトルネックは高いFPSで発生する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%81%AE%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF%E3%81%AF%E9%AB%98%E3%81%84FPS%E3%81%A7%E7%99%BA%E7%94%9F%E3%81%99%E3%82%8B",
        "aria-label": "CPUのボトルネックは高いFPSで発生する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUのボトルネックは高いFPSで発生する`}</h2>
    <p>{`ここまでの話でピンと来ている人はいるかもしれないが、GPUで１フレーム作成する処理をいくら16.66ms以内に納めたとしても、CPUが各フレームで発生するタスクを処理できなければフレームレートを上げることができない。`}</p>
    <p>{`そして高フレームレートを目指そうとしたときにはリソース制約は更に厳しくなり、４倍となる240Hzのフレームレートを実現するためには、わずか4ms強でCPU処理を完了しなければならない。`}</p>
    <p>{`つまり`}<strong parentName="p"><em parentName="strong">{`高いFPSを目指すほど、短時間で同一の処理をCPUは処理しなければならない`}</em>{`ためCPUのボトルネックが発生しやすい`}</strong>{`のである。`}</p>
    <p>{`ゲームによりけりであるが、特にFullHDでゲームを行う場合にはどんなに良いGPUを搭載しようとも一定以上FPSが上がらないことはよくある。`}</p>
    <h2 {...{
      "id": "4KゲームでCPUがボトルネックとなる可能性は低い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4K%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%A7CPU%E3%81%8C%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF%E3%81%A8%E3%81%AA%E3%82%8B%E5%8F%AF%E8%83%BD%E6%80%A7%E3%81%AF%E4%BD%8E%E3%81%84",
        "aria-label": "4KゲームでCPUがボトルネックとなる可能性は低い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4KゲームでCPUがボトルネックとなる可能性は低い`}</h2>
    <p>{`一方で4Kなど高解像度を目指したときは、物理演算は多少細かくなる可能性はあるものの、敵のAI処理や通信処理、サウンドエフェクトなど多くの処理は解像度の違いによらず一定の負荷である。`}</p>
    <p><strong parentName="p">{`4Kではグラフィックボードの性能が不足しがちで高いFPSが出にくいため、ボトルネックとなるのは専らGPUであり、`}<em parentName="strong">{`CPUがボトルネックとなる可能性は相対的に低い`}</em></strong>{`。`}</p>
    <h2 {...{
      "id": "4KならミドルレンジのCPU、高FPSならハイエンドのCPU",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4K%E3%81%AA%E3%82%89%E3%83%9F%E3%83%89%E3%83%AB%E3%83%AC%E3%83%B3%E3%82%B8%E3%81%AECPU%E3%80%81%E9%AB%98FPS%E3%81%AA%E3%82%89%E3%83%8F%E3%82%A4%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AECPU",
        "aria-label": "4KならミドルレンジのCPU、高FPSならハイエンドのCPU permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4KならミドルレンジのCPU、高FPSならハイエンドのCPU`}</h2>
    <p>{`以上の話により、`}<strong parentName="p"><em parentName="strong">{`4Kクオリティの映像を楽しみたい場合にはそこまでCPUの能力は求められないためミドルレンジのCPUで構わない`}</em></strong>{`。`}</p>
    <p>{`一方で144Hzなど`}<strong parentName="p"><em parentName="strong">{`高いフレームレートを目指したいならば、CPUがより短時間でタスクをこなす必要がでてくるためハイエンドのCPUを搭載しなければならない`}</em></strong>{`というのが結論である。`}</p>
    <p>{`ハイエンドのCPUは高いものでは10万円近くするため、遊び方次第ではスペックを合理的に落とすことが可能なのである。`}</p>
    <p>{`また、可能な限りCPUとGPUを限界まで活用したいという場合、`}<strong parentName="p">{`うまい具合に画質をチューニングすることも重要`}</strong>{`である。`}</p>
    <p>{`たとえばレイトレーシングと呼ばれる、光の反射を考慮してよりリアルな映像に近づける技術があるが、ピクセルごとの計算量が大幅に増えるためオン・オフを設定で切り替えられるゲームがある。`}</p>
    <p>{`GPUの能力が余っていると感じたら、これをオンにすると良いだろう。`}</p>
    <h2 {...{
      "id": "メーカー推奨スペックは合理的である",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E6%8E%A8%E5%A5%A8%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AF%E5%90%88%E7%90%86%E7%9A%84%E3%81%A7%E3%81%82%E3%82%8B",
        "aria-label": "メーカー推奨スペックは合理的である permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メーカー推奨スペックは合理的である`}</h2>
    <Image {...props} name="requirement_hq" caption="Call Of Dutyの推奨スペック" mdxType="Image" />
    <p>{`遊びたいゲームが決まっている場合はメーカーの推奨スペックを確認することは非常に合理的である。`}</p>
    <p>{`一番無駄のないCPUとGPUの組み合わせは、両方のプロセッサーが力を持て余すことなく負荷がかかるような組み合わせである。`}</p>
    <p>{`多くのゲームでは、同レンジのCPUとGPUを組み合わせて、ボトルネックがどちらにあるかを実験してパフォーマンスチューニングを行っており、ハイエンドGPUとCeleronの組み合わせなどは試していないであろう。`}</p>
    <p>{`このため一般的にはこの開発スタイルに合わせ、特に4Kではなく高いフレームレートを目指したい場合は`}<strong parentName="p"><em parentName="strong">{`同レンジのCPUとGPUを組み合わせて使う`}</em>{`のが一般的には良いという事`}</strong>{`になる。`}</p>
    <p>{`ところがソフトウェアの作り方による違いはゲームごとに大きいため、同じパソコンのスペックでも、あるゲームは標準的な画質でGPUがボトルネックになるが、他のゲームではCPUがボトルネックとなるのが普通である。`}</p>
    <p>{`そこで`}<strong parentName="p">{`やりたいゲームが決まっている場合のみであるが、メーカーの推奨スペックを確認することをおすすめする。そこに`}<em parentName="strong">{`CPUとGPUのちょうど良い均衡点が書かれている`}</em></strong>{`ためである。`}</p>
    <p>{`ただし一般的にどのメーカーサイトでも4Kの推奨CPUは大抵の場合やや過剰な気がする。ともあれ最強の環境を作りたいという場合は従えば良いだろう。`}</p>
    <h2 {...{
      "id": "CPUボトルネックチェッカーは役に立たない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#CPU%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF%E3%83%81%E3%82%A7%E3%83%83%E3%82%AB%E3%83%BC%E3%81%AF%E5%BD%B9%E3%81%AB%E7%AB%8B%E3%81%9F%E3%81%AA%E3%81%84",
        "aria-label": "CPUボトルネックチェッカーは役に立たない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUボトルネックチェッカーは役に立たない`}</h2>
    <p>{`CPUとGPUのどちらがボトルネックになるのかは4KかFullHDかQHDか？同じ解像度でもそれぞれのディテールの描写は細かいか荒いか？それぞれのゲームでのチューニングの度合いなどで決まるため、ある`}<strong parentName="p"><em parentName="strong">{`CPUに対してこのGPUが最適であると一意に特定することはできない`}</em></strong>{`。`}</p>
    <p>{`言えるとするならばたとえば、フォートナイトをレイトレーシング有、DLSS有設定でQHD画質で遊ぶことを考えた時、Geforce 3060Tiをグラフィックボードとして選択すれば、ボトルネックとならないようなCPUはCore i7 〇〇Kである、みたいな超具体的な答えとなってしまうだろう。`}</p>
    <p>{`よってボトルネックチェッカーというサービスがあるが基本的に目安にすらならないだろう。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`以上、言いたいことをまとめる。`}</p>
    <List mdxType="List">
      <li>4Kゲームを楽しむならばハイエンドGPUとミドルエンドCPUが合理的</li>
      <li>高フレームレートを目指すならばGPUとCPUでレンジを合わす</li>
      <li>やりたいゲームが決まっているならばメーカー推奨スペックからCPUとGPUの均衡点を探る</li>
    </List>
    <p>{`これを念頭に入れつつ自分にとってコスパの良いCPUとGPUの組み合わせを決めてもらえれば幸いである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      